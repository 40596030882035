<template>
  <div class="operation_manual_container">
    <div class="top">
      <div class="main-title">运营手册<font> / OPERATION MANUAL</font>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <div class="bg"></div>
        <div class="left">
          <img class="book" src="/static/img/league/operation_manual/top_left.png" />
          <img class="badge" src="/static/img/league/operation_manual/top_left_badge.png" />
        </div>
        <div class="center">
          <img class="book" src="/static/img/league/operation_manual/top_center.png" />
        </div>
        <div class="right">
          <img class="book" src="/static/img/league/operation_manual/top_right.png" />
          <img class="badge" src="/static/img/league/operation_manual/top_right_badge.png" />
        </div>
      </div>
      <div class="bottom">
        <div class="manual" v-for="(bottomImg, idx) in bottomImgs" :key="idx">
          <img :src="bottomImg.pic">
          <span>{{bottomImg.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bottomImgs: [
        {
          name: "前台手册",
          pic: "/static/img/league/operation_manual/bottom_1.png",
        },
        {
          name: "参考手册",
          pic: "/static/img/league/operation_manual/bottom_2.png",
        },
        {
          name: "APP手册",
          pic: "/static/img/league/operation_manual/bottom_3.png",
        },
        {
          name: "法律手册",
          pic: "/static/img/league/operation_manual/bottom_4.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.operation_manual_container {
  position: relative;
  z-index: -2;
  background: rgba($color: #f5f5f5, $alpha: 0.5);

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }
  .content {
    margin-top: 70px;
    // display: flex;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .bg {
        margin-top: 240px;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 286px;
        background: linear-gradient(157deg, #ffa83a 0%, #ff6f1b 100%);
        opacity: 0.65;
        -webkit-user-drag: none;
      }

      .left {
        position: relative;
        .book {
          width: 342px;
          height: 381px;
          -webkit-user-drag: none;
        }
        .badge {
          position: absolute;
          width: 151px;
          height: 106px;
          left: -25px;
          bottom: 0px;
          -webkit-user-drag: none;
        }
      }

      .center {
        margin-left: 43px;
        margin-right: 39px;

        .book {
          width: 317px;
          height: 496px;
          -webkit-user-drag: none;
        }
      }

      .right {
        position: relative;
        .book {
          width: 343px;
          height: 383px;
          -webkit-user-drag: none;
        }
        .badge {
          position: absolute;
          width: 151px;
          height: 106px;
          right: -25px;
          bottom: 0px;
          -webkit-user-drag: none;
        }
      }
    }

    .bottom {
      display: flex;
      margin-top: 52px;
      padding-bottom: 80px;
      justify-content: center;

      .manual {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          -webkit-user-drag: none;
          width: 255px;
          height: 355px;
          margin-right: 35px;

          &:last-child {
            margin-right: 0;
          }
        }

        span {
          margin-top: 12px;

          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;

          width: 143px;
          height: 41px;
          background: #ff6f1b;
          border-radius: 21px;

          font-size: 19px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 26px;
        }
      }
    }
  }
}
</style>