<template>
  <div class="join_now_container">
    <div class="head">
      <div class="main-title">意向提交<font> / INTENTION SUBMIT</font>
      </div>
    </div>
    <div class="body">
      <div class="left">
        <div class="text">
          <input v-model="form.name" placeholder="您的姓名">
        </div>
        <div class="text">
          <input v-model="form.mobile" placeholder="您的电话">
        </div>
        <div class="text">
          <input v-model="form.region" placeholder="意向区域：XX省XX市XX区或XX县">
        </div>
        <!-- <div class="select">
          <SelectRegion ref="selectRegion" @change="selectRegionChangeHandler" :args="args.region" placeholder="意向区域" />
        </div> -->
      </div>
      <div class="right">
        <textarea v-model="form.remark" class="textarea" placeholder="我要留言"></textarea>
      </div>
    </div>
    <div class="foot">
      <div class="submit" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
// import SelectRegion from "@/components/common/select_region.vue";
export default {
  components: {
    // SelectRegion,
  },

  data() {
    return {
      form: {
        name: "",
        mobile: "",
        region: "",
        remark: "",
      },
    };
  },
  methods: {
    submit() {
      this.$api("/official/page/join_now_form", this.form).then(({ msg }) => {
        this.$element.Message({
          message: msg,
          type: "success",
          offset: document.documentElement.clientHeight * 0.1,
        });
        this.$refs.selectRegion.clear();
        this.form = {
          name: "",
          mobile: "",
          region: "",
          remark: "",
        };
      });
    },
    // selectRegionChangeHandler(data) {
    //   this.form.region = data.region;
    // },
  },
  props: {
    args: {
      type: Object,
      deafult: () => {
        return {
          region: {},
        };
      },
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  font-family: inherit;
}
.join_now_container {
  width: 100%;
  height: 651px;
  background-color: rgba($color: #ff6f1b, $alpha: 0.07);

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 50px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }

  .body {
    display: flex;
    margin-top: 89px;
    // align-items: center;
    justify-content: center;

    .left {
      width: 570px;
      margin-right: 39px;

      .text {
        position: relative;
        &::after {
          content: "*";
          font-size: 27px;
          color: #ff4d00;
          position: absolute;
          right: 30px;
          top: 22px;
        }

        input {
          width: 570px;
          height: 60px;
          background: #ffffff;
          border-radius: 41px;
          outline: none;
          border: none;

          text-indent: 30px;

          font-size: 16px;
          &::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #ff6f1b;
            font-size: 16px;
          }

          &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #ff6f1b;
            font-size: 16px;
          }

          &::-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #ff6f1b;
            font-size: 16px;
          }
        }
      }

      > * {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .right {
      .textarea {
        // width: 570px;
        width: 550px;
        height: 220px;
        // height: 240px;
        border-radius: 26px;
        padding: 20px;
        border: none;
        outline: none;
        resize: none;

        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #ff6f1b;
          font-size: 16px;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #ff6f1b;
          font-size: 16px;
        }

        &::-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #ff6f1b;
          font-size: 16px;
        }
      }
    }
  }

  .foot {
    margin-top: 85px;
    display: flex;
    justify-content: center;

    .submit {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 380px;
      height: 60px;
      background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
      border-radius: 30px;
      border: 1px sliod rgba($color: #fff, $alpha: 0);

      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 33px;

      transition: 0.5s;
      cursor: pointer;

      user-select: none;

      &:hover {
        color: #ff6f1b;
        border: 1px solid #ff6f1b;
        background: none;
      }
    }
  }
}
</style>