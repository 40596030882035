<template>
  <div class="container">
    <Carousel tip="加入" :args="args.slide" />

    <!-- <Carousel arrow="never" tip="加入" :args="[{media:'/static/img/league/top_pic.png', media_type:0}]" /> -->
    <!-- <div v-if="false"> -->
    <OurAdvantage />
    <CampusDecoration />
    <OperationManual />
    <Join />
    <!-- </div> -->
    <JoinNow :args="args.join_now" />
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
import OurAdvantage from "@/components/league/our_advantage.vue";
import CampusDecoration from "@/components/league/campus_decoration.vue";
import OperationManual from "@/components/league/operation_manual.vue";
import Join from "@/components/league/join.vue";
import JoinNow from "@/components/league/join_now.vue";

export default {
  components: {
    Carousel,
    OurAdvantage,
    CampusDecoration,
    OperationManual,
    Join,
    JoinNow,
  },
  data() {
    return {
      args: {},
    };
  },
  created() {
    this.$api("/official/page/league").then(({ data }) => {
      this.args = data;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

