<template>
  <div class="our_advantage_container">
    <div class="top">
      <div class="main-title">品牌优势<font> / OUR ADVANTAGE</font>
      </div>
    </div>
    <div class="content">
      <img class="relation" src="/static/img/league/our_advantage/relation.png?v=1">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.our_advantage_container {
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }
  .content {
    margin-top: 100px;

    display: flex;
    justify-content: center;

    .relation {
      -webkit-user-drag: none;
      width: 1000px;
      height: 551px;
    }
  }
}
</style>