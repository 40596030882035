<template>
  <div class="our_advantage_container">
    <div class="top">
      <div class="main-title">校区装修<font> / CAMPUS DECORATION</font>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <img class="top-img" src="/static/img/league/campus_decoration/top.png" />
        <div class="down-block">
          <font class="tip" @click="scrollDownPage(1)" style="cursor:pointer">前台</font>
          <img class="down" @click="scrollDownPage(1)" style="cursor:pointer" src="/static/img/down.png" />
        </div>
      </div>
      <div class="bottom">
        <img src="/static/img/league/campus_decoration/bottom_1.png">
        <img src="/static/img/league/campus_decoration/bottom_2.png">
        <img src="/static/img/league/campus_decoration/bottom_3.png">
        <img src="/static/img/league/campus_decoration/bottom_4.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.our_advantage_container {
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 100px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }
  .content {
    margin-top: 50px;
    .top {
      position: relative;

      .top-img {
        width: 100%;
        height: 849px;
      }

      .down-block {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        z-index: 20;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        animation: jumpUpDown 1.5s infinite;

        @keyframes jumpUpDown {
          0% {
            transform: translate(0px, 0px);
          }
          50% {
            transform: translate(0px, -10px);
          }
          100% {
            transform: translate(0px, 0px);
          }
        }

        .down {
          width: 25px;
          height: 14px;
          margin-top: 10px;
        }

        .tip {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 25px;
        }
      }
    }

    .bottom {
      margin-top: 90px;
      padding-bottom: 1px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      img {
        -webkit-user-drag: none;

        width: 574px;
        height: 409px;
        border-radius: 16px;

        &:nth-child(2n - 1) {
          margin-right: 30px;
        }
        &:nth-child(0),
        &:nth-child(1) {
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>