<template>
  <div class="join_container">
    <div class="head">
      <div class="main-title">加盟须知<font> / JOIN NOTICE</font>
      </div>
    </div>
    <div class="body">
      <div class="text" v-for="(text, idx) in texts" :key="idx">
        <div class="top">
          <div :class="{number:true, actived:idx==0}">{{idx + 1}}</div>
          <div class="title">{{text.title}}</div>
        </div>
        <div class="content">
          <pre>{{text.content}}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texts: [
        {
          title: "须知一：理念认同",
          content: `1、企业愿景：演播行业，龙头品牌；
2、企业使命：传播汉语，赋能未来；
3、企业理念：趣味演播，双线课堂；
4、企业价值：教学是天，服务是地；
5、企业精神：疯狂工作，快乐生活。`,
        },
        {
          title: "须知二：校长素养",
          content: `1、热爱主持表演，乐于传播汉语；
2、积极乐观，有责任心、上进心；
2、有播音、表演、汉语、教育等专业学历；
3、有经济实力和办学经验，诚信实干，遵纪守法；
4、重视教学、服务、口碑，线上线下结合，遵循教育本质。`,
        },
        {
          title: "须知三：办学规范",
          content: `1、消防合格，300平米场地；
2、按校区装修图纸进行装修；
3、有营业执照和办学许可证； 
4、学生收费季度，资金监管； 
5、使用合法教材和校管APP；
6、拥有教师资格和专业证书。`,
        },
        {
          title: "须知四：优惠对象",
          content: `1、具有中传、中戏和上戏、浙传、北影等院校演播本科学历的意向者；
2、具有教育、新闻、表演、播音、曲艺等学院专科以上学历的意向者；
2、具有广电主持人播音员资格证或普通话一等及有教育经验的意向者； 
3、经内部校长推荐，具有演播艺术专业素养的意向者；
4、偏远和贫困地区，具有演播艺术专业素养的意向者。`,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.join_container {
  .head {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }
  .body {
    height: 1184px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 100px;

    .text {
      padding-bottom: 20px;
      position: relative;
      width: 667px;
      padding-left: 40px;

      border-left: 1px solid #ff6f1b;
      &:last-child {
        border: none;
      }

      .top {
        display: flex;

        .number {
          position: absolute;
          left: -18px;
          top: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          width: 32px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #ff6f1b;
          border-radius: 100%;

          font-size: 14px;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff6f1b;
          line-height: 20px;

          &.actived {
            width: 29px;
            height: 29px;
            color: white;
            background: #ff6f1b;
            border: 4px solid #ffa83a;
          }
        }

        .title {
          font-size: 26px;
          // font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff6f1b;
          line-height: 28px;
        }
      }

      .content {
        pre {
          white-space: pre-wrap;
          font-family: inherit;
          margin: 0;
        }
        padding-top: 40px;
        padding-bottom: 60px;

        font-size: 14px;
        color: #333;
        line-height: 28px;
        // font-family: PingFangSC-Medium, PingFang SC;
      }
    }
  }
}
</style>